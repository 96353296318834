import * as isActive from './activityFns.js'
import * as singleSpa from 'single-spa'
import '../node_modules/antd/dist/antd.css';
import { signIn, isAuthenticated, isRedirectFromAuthentication, saveCurrentLocation, restorePreviousLocation, getSavedLocation } from "./AuthProvider";

 if(isRedirectFromAuthentication() || getSavedLocation()) {
  restorePreviousLocation();
 }
 

setTimeout(function() {
  if(isAuthenticated()) {

    singleSpa.registerApplication('shell', () => SystemJS.import('@portal/shell'), isActive.shell)
    singleSpa.registerApplication('start', () => SystemJS.import('@portal/start'), isActive.start)
    singleSpa.registerApplication('administration', () => SystemJS.import('@portal/administration'), isActive.administration)
    singleSpa.registerApplication('company', () => SystemJS.import('@portal/company'), isActive.company)
    singleSpa.start();
  } else {
    saveCurrentLocation();
    signIn();
  }
}, 500);

