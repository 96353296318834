export function prefix(location, ...prefixes) {
  return prefixes.some(
    prefix => (
      location.href.indexOf(`${location.origin}/${prefix}`) !== -1
    )
  )
}

export function shell(location) {
  return true
}

export function administration(location) {
  return prefix(location, 'administration')
}

export function start(location) {
  return prefix(location, 'start')
}

export function company(location) {
  return prefix(location, 'company')
}
